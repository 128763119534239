<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >

    <base-material-card
      icon="mdi-message"
      title="SMS List"
      class="px-5 py-3"
    >
      <v-simple-table>
        <filter-list
          :filters="filter"
          :on-change="getSms">
        </filter-list>

        <tbody>
        <tr v-for="sms in SmsList">
          <td>{{ sms.duration | dateDuration }}</td>
          <td>{{ sms.sms_text }}</td>
          <td>
            <div class="d-inline-flex">
            <v-btn color="ma-2 info float-right" fab x-small dark :to="'/sms/create-change-request/' + sms.duration">
              <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
            <v-btn color="ma-2 info float-right" fab x-small dark :to="'/sms/change-requests/' + sms.duration">
              <v-icon dark>mdi-view-list</v-icon>
            </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3"/>
  </v-container>
</template>

<script>
  import FilterList from '../../components/base/FilterList'

  export default {
    name: 'SMSList',
    components: { FilterList },
    watch: {
      $route () {
        this.getUrlParams()
        this.getSms()
      },
    },
    data () {
      return {
        SmsList: [],
        query: {
        },
        filter: [
          {
            name: 'duration',
            value: '',
            type: 'text_field',
          },
          {
            name: 'sms_text',
            value: '',
            type: 'text_field',
          }
        ],
      }
    },
    created () {
      this.getSms()
    },
    methods: {
      getUrlParams () {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1,
          }
          this.filter = [
            {
              name: 'duration',
              value: '',
              type: 'text_field',
            },
            {
              name: 'sms_text',
              value: '',
              type: 'text_field',
            }
          ]
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query
          this.filter.forEach(param => {
            param.value = this.query[param['name']]
          })
        }
      },
      getSms () {
        this.$authApi.get('/partner-sms', {
          params: this.query
        }).then(res => {
          this.SmsList = res.data.data
        })
      },
    },
  }
</script>

<style scoped>

</style>
